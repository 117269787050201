import { defineStore } from 'pinia'

import _ from 'lodash'
import { ManualCommunication, SideBarSection } from '../../../types/GCB2'
import store from '../..'
import messages from '@dataheroes/messages'
import api from '../../../api/api'
export const useTaskListBatchModeStore = defineStore('taskListBatchMode', {
    state: () => ({
        selectedCommunications: [] as ManualCommunication[],
        selectedCommunicationsBeforeChange: [] as ManualCommunication[],
        sendMessageLimit: {} as { [projectId: string]: number },
        addDailyLimitModalOpened: false as boolean,
    }),
    actions: {
        updateMessage({ communicationId, message }: any) {
            const communicationToChange = this.selectedCommunications.find(
                el => el.communicationId === communicationId
            )
            Object.assign(communicationToChange as any, { textMessage: message })
        },
        getMessagingConnector(projectId: string) {
            const project = store.getters.projectById(projectId)
            return messages.getMessagingConnector(project.connectors)
        },
        async fetchSendMessageLimit(projectId: string) {
            const messagingConnector = this.getMessagingConnector(projectId)
            const limit = await api.massender
                .getLimit({
                    projectId,
                    connectorType: messagingConnector?.connectorType!,
                })
                .then(res => +res.data?.limit)

            this.sendMessageLimit = Object.assign({}, this.sendMessageLimit, { [projectId]: limit })
        },
        async consumeMessageLimit(projectId: string, limit: number) {
            const messagingConnector = this.getMessagingConnector(projectId)
            const currentLimit = await api.massender
                .consumeLimit({
                    projectId,
                    connectorType: messagingConnector?.connectorType!,
                    limit,
                })
                .then(res => +res.data?.limit)
            this.sendMessageLimit = Object.assign({}, this.sendMessageLimit, { [projectId]: currentLimit })
        },
        async addDailyMessageLimit(projectId: string, limit: number) {
            const messagingConnector = this.getMessagingConnector(projectId)
            const currentLimit = await api.massender
                .addDailyLimit({
                    projectId,
                    connectorType: messagingConnector?.connectorType!,
                    limit,
                })
                .then(res => +res.data?.limit)
            this.sendMessageLimit = Object.assign({}, this.sendMessageLimit, { [projectId]: currentLimit })
        },
        setAddDailyLimitModalOpened(value: boolean) {
            this.addDailyLimitModalOpened = value
        },
    },
    getters: {
        selectedCommunicationsIds(): string[] {
            return _.map(this.selectedCommunications, 'communicationId')
        },
        selectedCommunicationsBeforeChangeIds(): string[] {
            return _.map(this.selectedCommunicationsBeforeChange, 'communicationId')
        },
        messageLimit: state => (projectId: string) => state.sendMessageLimit[projectId],
        messageLimitIncludeSelected: state => (projectId: string) =>
            state.sendMessageLimit[projectId] - state.selectedCommunications.length,
    },
})
